import { CdkDragDrop, CdkDragStart } from "@angular/cdk/drag-drop";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatSliderChange } from "@angular/material/slider";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  DragDropService,
  POI,
  ProtocolSpecification,
  ROI,
  changeCropLabels,
  dragCrop,
  loadMore,
  roisSorted,
  resetMosaicSelectedLabel,
  CropInfo,
  selectLoadingAnalysis,
  selectActiveCount,
  getGroupedMosaics,
  getProtocolTasks,
  getProtocolOptions,
  GroupedMosaic,
  preSyncAnalysis,
  RoiService,
  CropDetail,
  selectLabelTracking,
} from "@telespot/analysis-refactor/data-access";
import { TAnalysisProtocolTask, TaskOption } from "@telespot/sdk";

import { MosaicService } from "@telespot/web-core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export enum OS {
  MacOs = "MacOs",
  Windows = "Windows",
  Linux = "Linux",
}

@Component({
  selector: "ts-sample-analysis-mosaics",
  templateUrl: "./sample-analysis-mosaics.component.html",
  styleUrls: ["./sample-analysis-mosaics.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleAnalysisMosaicsComponent
  implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy
{
  // Contextual Menu variables
  public contextMenuVisible = false;
  public cropDetailMenuVisible = false;
  public contextMenuPosition = { x: 0, y: 0 };
  public scale = 1;
  public selectedItemLabels: Array<string> = [];
  public selectedCropDetail: CropDetail;
  public selectedCropUrl;

  @ViewChild("contextMenu", { read: ElementRef })
  private contextMenuRef: ElementRef;
  @ViewChild("scrollContainer") scrollContainer: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  @ViewChildren("gallery") galleries: QueryList<ElementRef>;
  @ViewChildren("selectionBox") selectionBoxes!: QueryList<ElementRef>;
  @ViewChildren("cropElement") cropElements: QueryList<ElementRef>;

  isDragging = false;
  startPoint = { x: 0, y: 0 };
  draggedSelection = false;
  currentGallery: ElementRef;
  private currentSelectionBox!: ElementRef;

  public UNLABELED_COLOR = "#F08F43";

  // Protocol and Mosaics variables
  @Input() protocol: ProtocolSpecification[] = [];

  public readonly rois$ = this._store.select(roisSorted);
  public readonly sampleStats$ = this._store.select(selectActiveCount);
  public readonly assetProtocol$ = this._store.select(getProtocolTasks);
  public readonly protocolOptions$ = this._store.select(getProtocolOptions);
  public readonly loading$ = this._store.select(selectLoadingAnalysis);
  public readonly labelsLeft$ = this._store.select(selectLabelTracking);

  public groupedMosaics$: Observable<GroupedMosaic[]>;
  public currentLang: string;
  public selectedCrops: CropInfo[];

  private _rois: (ROI | POI)[] = [];
  public isSelecting = false;

  // Drag and Drop variables
  private labelDict: { [label: string]: string } = {};

  // Component LifeCycle variables
  private destroy$ = new Subject<void>();
  private registeredDropListIds: string[] = [];

  private _os: OS;
  public hoverLabel = "";
  public protocolLabels;
  private _itemsPerRow = 1;
  private _hasCalculatedItemsPerRow = false;
  private labelsLeft: boolean;

  constructor(
    public dragDropService: DragDropService,
    private _mosaicService: MosaicService,
    private _roiService: RoiService,
    private _cdr: ChangeDetectorRef,
    private _store: Store,
    private translateService: TranslateService
  ) {
    this.currentLang =
      localStorage.getItem("user_language") ||
      this.translateService.currentLang;
  }

  /* COMPONENT LYFECYCLE MANAGEMENT FUNCTIONS */

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.protocol?.currentValue) return;

    this.rois$.pipe(takeUntil(this.destroy$)).subscribe((rois) => {
      this._rois = rois;
    });

    this.groupedMosaics$ = this._store.select(getGroupedMosaics);

    // Construct the label dictionary for Drag and Drop actions
    this.protocolOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((options) => {
        this.labelDict = options.reduce(
          (acc, curr) => ({ ...acc, [curr.value]: curr.uuid }),
          {}
        );
        this.protocolLabels = options;
      });
  }

  ngOnInit() {
    this._store.dispatch(resetMosaicSelectedLabel());

    this.groupedMosaics$
      .pipe(takeUntil(this.destroy$))
      .subscribe((groupedMosaics) => {
        this.registeredDropListIds.forEach((id) =>
          this.dragDropService.removeDropListId(id)
        );
        this.registeredDropListIds = [];

        groupedMosaics.forEach((categoryObj) => {
          Object.keys(categoryObj.labels).forEach((label) => {
            this.dragDropService.addDropListId(label);
            this.registeredDropListIds.push(label);
          });
        });
      });

    this.dragDropService.selectedCrops$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedCrops) => (this.selectedCrops = selectedCrops));

    window.addEventListener("resize", this.calculateItemsPerRow.bind(this));

    this.labelsLeft$.pipe(takeUntil(this.destroy$)).subscribe((labels) => {
      this.labelsLeft = labels.length ? true : false;
    });
  }

  ngAfterViewInit(): void {
    this.calculateItemsPerRow();
  }

  ngAfterViewChecked(): void {
    if (
      this.cropElements &&
      this.cropElements.length &&
      !this._hasCalculatedItemsPerRow
    ) {
      this._hasCalculatedItemsPerRow = true; // To prevent multiple executions
      this.calculateItemsPerRow();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.deselectAll();

    this.registeredDropListIds.forEach((id) =>
      this.dragDropService.removeDropListId(id)
    );
  }

  /* CONTEXTUAL MENU FUNCTIONS */

  public openContextMenu(event: MouseEvent, cropInfo: CropInfo): void {
    event.preventDefault();
    event.stopPropagation();

    if (this._os && this._os === OS.MacOs && event.button === 0) {
      this.onCropClick(event, cropInfo);
      return;
    }

    if (this.isSelecting) {
      if (!this.cropIsIncluded(this.selectedCrops, cropInfo)) {
        this.dragDropService.addSelectedCrop(cropInfo);
      }
    } else {
      if (
        !this.selectedCrops.map((crop) => crop.roiID).includes(cropInfo.roiID)
      ) {
        this.dragDropService.cleanSelectedCrops();
        this.dragDropService.addSelectedCrop(cropInfo);
      }
    }

    this.contextMenuPosition.x = event.clientX;
    this.contextMenuPosition.y = event.clientY;

    const findRois = (roiId) => this._rois.find((roi) => roi.id === roiId);
    const findLabelsForFinding = (roi, findingId) =>
      roi.labels.find((label) => label.findingId === findingId);

    const labels = this.selectedCrops.flatMap((crop) => {
      const roi = findRois(crop.roiID);
      const labelItem = findLabelsForFinding(roi, crop.findingId);

      return this.objectKeys(labelItem.labels);
    });

    labels.forEach((uuid) => this.selectedItemLabels.push(uuid));

    this.contextMenuVisible = true;
    this._cdr.detectChanges();

    const rect = this.contextMenuRef.nativeElement.getBoundingClientRect();
    const bottomSpace = window.innerHeight - event.clientY;

    if (rect.height > bottomSpace) {
      this.contextMenuPosition.y -= rect.height;
    }
  }

  public closeContextMenu(): void {
    this.contextMenuVisible = false;
    this.selectedItemLabels = [];
    this.hoverLabel = "";
  }

  public get doubleCheckedOptions(): string[] {
    if (this.selectedCrops.length < 2) return [];

    return this.selectedItemLabels.filter((labelId) =>
      this.isLabelInAllCrops(this.getLabelName(labelId))
    );
  }

  public onMenuClick(
    selectedLabel: TaskOption,
    selectedCategory: TAnalysisProtocolTask,
    protocolLabels: TaskOption[]
  ): void {
    const labelsToReplace = selectedCategory.options.filter((option) =>
      this.selectedItemLabels.includes(option.uuid)
    );

    const unselectLabel =
      labelsToReplace.length === 1 &&
      labelsToReplace[0].name === selectedLabel.name;

    const isLabelSelected = this.selectedItemLabels.includes(
      selectedLabel.uuid
    );
    const isInAllCrops = this.isLabelInAllCrops(selectedLabel.name);

    let actions;

    if (isLabelSelected && isInAllCrops) {
      this.selectedItemLabels = this.selectedItemLabels.filter(
        (l) => l !== selectedLabel.uuid
      );

      actions = this.selectedCrops.map((crop) =>
        changeCropLabels({
          roiId: crop.roiID,
          findingId: crop.findingId,
          newLabel: selectedLabel.uuid,
        })
      );
    } else if (isLabelSelected) {
      const crops = this.getCropsWithoutLabel(selectedLabel.name);

      actions = crops.map((crop) =>
        changeCropLabels({
          roiId: crop.roiID,
          findingId: crop.findingId,
          newLabel: selectedLabel.uuid,
        })
      );
    } else if (labelsToReplace.length && !unselectLabel) {
      this.selectedItemLabels = protocolLabels
        .filter((l) => !labelsToReplace.some((rl) => rl.uuid === l.uuid))
        .map((l) => l.uuid);

      actions = this.selectedCrops.map((crop) =>
        dragCrop({
          roiId: crop.roiID,
          previousLabels: labelsToReplace.map((label) => label.uuid),
          newLabels: [selectedLabel.uuid],
        })
      );

      this.deselectAll();
    } else {
      actions = this.selectedCrops.map((crop) =>
        changeCropLabels({
          roiId: crop.roiID,
          findingId: crop.findingId,
          newLabel: selectedLabel.uuid,
        })
      );

      this.selectedItemLabels.push(selectedLabel.uuid);
    }

    actions.forEach((a) => this._store.dispatch(a));

    if (unselectLabel) this.closeContextMenu();
  }

  /* DRAG AND DROP FUNCTIONS */

  public onDrop(event: CdkDragDrop<CropInfo[]>): void {
    if (event.previousContainer !== event.container) {
      const origin = this.labelDict[event.previousContainer.id];
      const dest = this.labelDict[event.container.id];

      this.selectedCrops.forEach((draggedCropInfo) => {
        const roiID: string = draggedCropInfo.roiID;
        this._store.dispatch(
          dragCrop({
            roiId: roiID,
            previousLabels: [origin],
            newLabels: [dest],
          })
        );
      });
    }

    this.selectedCrops.forEach((crop) => {
      const element = document.querySelector(`[data-roiid="${crop.roiID}"]`);
      if (element) {
        element.classList.remove("dragging");
      }
    });

    this.deselectAll();
  }

  onDragStart(event: CdkDragStart, cropInfo: CropInfo): void {
    if (this.contextMenuVisible) this.closeContextMenu();
    if (this.cropDetailMenuVisible) this.closeCropDetail();

    if (!this.cropIsIncluded(this.selectedCrops, cropInfo)) {
      this.dragDropService.cleanSelectedCrops();
      this.dragDropService.addSelectedCrop(cropInfo);
    }

    if (this.isSelecting && !this.cropIsIncluded(this.selectedCrops, cropInfo))
      this.dragDropService.addSelectedCrop(cropInfo);

    event.source.data = this.selectedCrops;

    this.selectedCrops.forEach((crop) => {
      const element = document.querySelector(`[data-roiid="${crop.roiID}"]`);
      if (element) {
        element.classList.add("dragging");
      }
    });
  }

  /* HELPER FUNCTIONS */

  public objectKeys(obj: Record<string, unknown>): string[] {
    return Object.keys(obj);
  }

  public getCrop(roiId: string) {
    const url = localStorage.getItem(`crop_${roiId}`);
    if (!url) {
      return null;
    }
    const sanitizedUrl = this._mosaicService.getCropSanitizedUrl(url);
    return sanitizedUrl;
  }

  loadMoreCrops() {
    if (!this.labelsLeft) return;
    this._store.dispatch(loadMore({ labelId: undefined }));
  }

  getLabelsHints(cropInfo: CropInfo): number {
    const labels = Object.keys(this.getLabelsFromRoi(cropInfo));
    return labels?.length || 0;
  }

  getLabelsFromRoi(cropInfo: CropInfo) {
    return this._rois
      .find((roi) => {
        return roi.id === cropInfo.roiID;
      })
      ?.labels.find((label) => label.findingId === cropInfo.findingId)?.labels;
  }

  onCropClick(event: MouseEvent, cropInfo: CropInfo): void {
    if (this.cropDetailMenuVisible) this.closeCropDetail();

    if (!this.isSelecting) {
      this.deselectAll();

      this.dragDropService.addSelectedCrop(cropInfo);

      event.stopPropagation();
      return;
    }

    this.cropIsIncluded(this.selectedCrops, cropInfo)
      ? this.dragDropService.removeSelectedCrop(cropInfo)
      : this.dragDropService.addSelectedCrop(cropInfo);

    event.stopPropagation();
  }

  onOutsideClick(): void {
    if (this.cropDetailMenuVisible) this.closeCropDetail();
    if (this.draggedSelection) {
      //Avoid cleaning selected crops just after of selecting crops through dragging
      this.draggedSelection = false;
      return;
    }
    this.deselectAll();
  }

  @HostListener("document:keydown", ["$event"])
  documentKeyDown(event: KeyboardEvent): void {
    if (event.metaKey || event.ctrlKey) {
      this.isSelecting = true;
      if (event.ctrlKey) {
        this._os = window.navigator.userAgent.toLowerCase().includes("windows")
          ? OS.Windows
          : window.navigator.userAgent.toLowerCase().includes("mac os")
          ? OS.MacOs
          : OS.Linux;
      }
      if (event.key === "s" || event.key === "S") {
        this._store.dispatch(preSyncAnalysis());
        event.preventDefault();
        event.stopPropagation();
      }
    }
    if (event.key === "Escape") {
      this.deselectAll();
      this.closeCropDetail();
    }
  }

  @HostListener("document:keyup", ["$event"])
  documentKeyUp(event: KeyboardEvent): void {
    const key = event.key.toLowerCase();
    const metaKey = "meta";
    const ctrlKey = "control";

    if (key === metaKey || key === ctrlKey) {
      this.isSelecting = false;
      if (key === ctrlKey) {
        this._os = undefined;
      }
    }
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(event: MouseEvent): void {
    if (!this.isSelecting || this.selectedCrops.length) return;
    const targetGallery = (event.target as HTMLElement).closest(
      ".mosaic-gallery"
    );
    if (!targetGallery) return;

    this.currentGallery = this.galleries.find(
      (gallery) => gallery.nativeElement === targetGallery
    );

    this.currentSelectionBox = this.selectionBoxes.find(
      (box) =>
        box.nativeElement.parentElement === this.currentGallery.nativeElement
    );

    if (!this.currentGallery || !this.currentSelectionBox) return;
    this.isDragging = true;

    const galleryRect =
      this.currentGallery.nativeElement.getBoundingClientRect();

    this.startPoint = {
      x: event.clientX - galleryRect.left,
      y: event.clientY - galleryRect.top,
    };

    const selectionBoxElem = this.currentSelectionBox.nativeElement;
    selectionBoxElem.style.position = "absolute";
    selectionBoxElem.style.left = `${this.startPoint.x}px`;
    selectionBoxElem.style.top = `${this.startPoint.y}px`;
    selectionBoxElem.style.width = "0px";
    selectionBoxElem.style.height = "0px";
    selectionBoxElem.style.display = "block";
  }

  @HostListener("mousemove", ["$event"])
  onMouseMove(event: MouseEvent): void {
    if (
      !this.isSelecting ||
      !this.isDragging ||
      !this.currentGallery ||
      !this.currentSelectionBox
    )
      return;

    const containerRect =
      this.currentGallery.nativeElement.getBoundingClientRect();
    const currentPoint = {
      x: event.clientX - containerRect.left,
      y: event.clientY - containerRect.top,
    };
    const width = currentPoint.x - this.startPoint.x;
    const height = currentPoint.y - this.startPoint.y;

    const selectionBoxElem = this.currentSelectionBox.nativeElement;
    // Update selection box dimensions
    selectionBoxElem.style.width = `${Math.abs(width)}px`;
    selectionBoxElem.style.height = `${Math.abs(height)}px`;

    if (width < 0) {
      selectionBoxElem.style.left = `${currentPoint.x}px`;
    }
    if (height < 0) {
      selectionBoxElem.style.top = `${currentPoint.y}px`;
    }
  }

  @HostListener("mouseup", ["$event"])
  onMouseUp(): void {
    if (this.isDragging && this.isSelecting) {
      this.checkSelection();
      this.currentGallery = null;
      this.currentSelectionBox.nativeElement.style.display = "none";
      this.draggedSelection = true;
      this.isDragging = false;
    }

    if (!this.selectedCrops.length) {
      if (this.currentSelectionBox)
        this.currentSelectionBox.nativeElement.style.display = "none";
      this.isDragging = false;
    }
  }

  doubleClick(
    event: MouseEvent,
    cropInfo: CropInfo,
    label: string,
    cropId
  ): void {
    this.cropDetailMenuVisible = true;
    this.selectedCropUrl = this.getCrop(cropInfo.roiID);
    this.selectedCropDetail = {
      currentLabel: label,
      labels: this.getLabelInfoFromCrop(cropInfo),
      cropId,
    };

    this._cdr.detectChanges();
  }

  checkSelection(): void {
    if (!this.currentGallery) return;
    const selectionRect =
      this.currentSelectionBox.nativeElement.getBoundingClientRect();

    const crops = Array.from(
      this.currentGallery.nativeElement.querySelectorAll(".mosaic-item")
    );

    const label = this.getLabelUuid(this.currentGallery.nativeElement.id);

    this.dragDropService.cleanSelectedCrops();

    crops.forEach((crop: HTMLElement) => {
      const cropRect = crop.getBoundingClientRect();

      if (this.intersects(selectionRect, cropRect)) {
        const roiId = crop.getAttribute("data-roiid");

        const roi = this._rois.find((roi) => roi.id === roiId);
        const score = roi.labels[0].labels[label];
        const roiInfo = {
          roiID: roi.id,
          findingId: roi.labels[0].findingId,
          crop: undefined,
          score,
        };
        this.dragDropService.addSelectedCrop(roiInfo);
      }
    });
  }

  intersects(rect1, rect2): boolean {
    return !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      Math.abs(rect1.bottom) < rect2.top ||
      rect1.top > rect2.bottom
    );
  }

  public deselectAll() {
    if (this.contextMenuVisible) this.closeContextMenu();
    this.dragDropService.cleanSelectedCrops();
  }

  getLabelUuid(value: string) {
    return (this.protocolLabels || []).find((l) => l.value === value)?.uuid;
  }

  getLabelName(uuid: string) {
    return (this.protocolLabels || []).find((l) => l.uuid === uuid)?.value;
  }

  public isLabelInAllCrops(labelName: string): boolean {
    return (
      this.getCropsWithLabel(labelName).length === this.selectedCrops.length
    );
  }

  public getCropsWithLabel(labelName: string): CropInfo[] {
    const crops = this.selectedCrops.filter((crop) => {
      const roi = this._rois.find((roi) => roi.id === crop.roiID);
      const labels = roi.labels.find(
        (label) => label.findingId === crop.findingId
      ).labels;
      return Object.keys(labels)
        .map((l) => this.getLabelName(l))
        .includes(labelName);
    });

    return crops;
  }

  public getCropsWithoutLabel(labelName: string): CropInfo[] {
    const crops = this.selectedCrops.filter((crop) => {
      const roi = this._rois.find((roi) => roi.id === crop.roiID);
      const labels = roi.labels.find(
        (label) => label.findingId === crop.findingId
      ).labels;
      return !Object.keys(labels).includes(this.labelDict[labelName]);
    });
    return crops;
  }

  onMenuItemEnter(label) {
    this.hoverLabel = label.value;
  }
  onMenuItemLeave() {
    this.hoverLabel = "";
  }

  getComplementaryColor(color: string): string {
    const { r, g, b, a } = this.rgbaStringToRgb(color);

    const compR = 255 - r;
    const compG = 255 - g;
    const compB = 255 - b;

    return a !== undefined
      ? `rgba(${compR}, ${compG}, ${compB}, ${a})`
      : `rgb(${compR}, ${compG}, ${compB})`;
  }

  rgbaStringToRgb(color: string): {
    r: number;
    g: number;
    b: number;
    a?: number;
  } {
    const rgbaMatch = color.match(
      /rgba?\((\d+), (\d+), (\d+),?\s*(\d?.\d+)?\)/
    );

    const [, r, g, b, a] = rgbaMatch;

    return {
      r: parseInt(r, 10),
      g: parseInt(g, 10),
      b: parseInt(b, 10),
      a: a ? parseFloat(a) : 1,
    };
  }

  getColor(uuid: string) {
    return this._roiService.getModelColor([uuid], true);
  }

  isSelected(cropInfo: CropInfo): boolean {
    return this.selectedCrops.some((crop) => crop.roiID === cropInfo.roiID);
  }

  isLabelHover(cropInfo: CropInfo): boolean {
    const hoverLabelUuid = this.getLabelUuid(this.hoverLabel);
    const crops = this.getCropsWithLabel(this.getLabelName(hoverLabelUuid));
    return crops.some((crop) => crop.roiID === cropInfo.roiID);
  }

  getLabelInfoFromCrop(cropInfo: CropInfo) {
    const labels = this.getLabelsFromRoi(cropInfo);
    return Object.keys(labels || {}).reduce(
      (acc, l) => ({ ...acc, [this.getLabelName(l)]: labels[l] }),
      {}
    );
  }

  onSliderChange(event: MatSliderChange) {
    if (event.value !== null) {
      this.scale = event.value;
      this.applyScale();
    }
  }

  increaseValue() {
    if (this.scale < 3) {
      this.scale += 0.5;
      this.applyScale();
    }
  }

  decreaseValue() {
    if (this.scale > 1) {
      this.scale -= 0.5;
      this.applyScale();
    }
  }

  applyScale() {
    const container = this.scrollContainer.nativeElement;
    container.style.transform = `scale(${this.scale})`;
    container.style.transformOrigin = "top left";
  }

  trackByCategory(index: number, item: GroupedMosaic): string {
    return item.category;
  }

  trackByLabel(index: number, label: string): string {
    return label;
  }

  trackByCropInfo(index: number, cropInfo: CropInfo): string {
    return cropInfo.roiID;
  }

  cropIsIncluded(selectedCrops: CropInfo[], cropInfo: CropInfo) {
    return selectedCrops.some(
      (c) => c.findingId === cropInfo.findingId && c.roiID === cropInfo.roiID
    );
  }

  closeCropDetail() {
    this.cropDetailMenuVisible = false;
    this.selectedCropDetail = null;
    this.deselectAll();
  }

  isFirstInRow(index: number): boolean {
    return index % this._itemsPerRow === 0;
  }

  calculateItemsPerRow() {
    if (!this.galleries.first) {
      this._itemsPerRow = 1;
      return;
    }

    const totalGalleryWidth = this.galleries.first.nativeElement.offsetWidth;
    const realGalleryWidth = totalGalleryWidth - 30 - 20; // Substract first column and padding from the totalWidth

    if (!this.cropElements.first) {
      this._itemsPerRow = 1;
      return;
    }
    const mosaicWidth = this.cropElements.first.nativeElement.offsetWidth + 4; // grid-gap

    this._itemsPerRow = Math.floor(realGalleryWidth / mosaicWidth);

    this._cdr.detectChanges();
  }
}
