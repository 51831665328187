import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

interface CropDetail {
  currentLabel: string;
  labels: { [key: string]: number };
  cropId: string;
}

@Component({
  selector: "ts-crop-detail",
  templateUrl: "./crop-detail.component.html",
  styleUrls: ["./crop-detail.component.scss"],
})
export class CropDetailComponent {
  @Input() imageUrl: string;
  @Input() cropDetail: CropDetail;
  @Output() closeDetail = new EventEmitter<void>();

  getLabels() {
    return Object.entries(this.cropDetail.labels).map(([key, value]) => ({
      key,
      value,
    }));
  }
}

@NgModule({
  declarations: [CropDetailComponent],
  imports: [CommonModule, TranslateModule],
  exports: [CropDetailComponent],
})
export class CropDetailModule {}
