<div class="crop-detail-wrapper">
  <div class="crop-detail-container">
    <button class="close-button" (click)="closeDetail.emit()">
      <i class="ri-close-line"></i>
    </button>
    <div class="header">
      <span class="title"
        >{{ cropDetail.currentLabel }} | {{ cropDetail.cropId }}</span
      >
    </div>
    <img *ngIf="imageUrl" [src]="imageUrl" loading="lazy" />
    <div class="labels-container" *ngIf="cropDetail">
      <div class="label-item" *ngFor="let label of getLabels()">
        <span class="label-name">{{ label.key }}</span>
        <div class="bar-container">
          <div class="bar" [ngStyle]="{ width: label.value * 100 + '%' }"></div>
        </div>
        <span class="label-value">{{ (label.value * 100).toFixed(0) }}%</span>
      </div>
    </div>
  </div>
</div>
